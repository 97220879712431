<template>
    <div>
		<b-row>
			<b-col
			md="8"
			lg="6"
			xl="4">
				<thanks-component></thanks-component>
			</b-col>
		</b-row>
    </div>
</template>
<script>
// Components
import ThanksComponent from '@/components/buyer/thanks/components/ThanksComponent'
export default {
	components: {
		ThanksComponent,
	},
}
</script>